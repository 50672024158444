@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.article-text{
  margin-left:1em;
}
.article-news{
  width:100%;
  border-radius:8px !important;
  margin: 0.5em 0 !important;
}
.news-image{
  border-radius: 1em;
  object-fit: cover;
}
.ant-card-body{
  padding:24px;
}
.news-image-container{
  display: flex;
  width: 100%;
}
a{
  text-decoration: none;
  color: black ;
}
.load-page{
  text-align: center;
  margin: 3em 0;
}
.news-text{
  display:block;
  font-size:15px;
}
.button_text{
  color:white;
  background-color: grey;
}
#state-map{
  background-color: rgb(250, 240, 230);
  height: auto;
  width: 100%; 
}
.main{
  background:linear-gradient(90deg, rgba(77,77,80,1) 100%, rgba(0,0,0,1) 100%); min-height: 80vh; 
}
.home-page{
  max-width:1200px;
  margin:0 auto;
}
.home_img{
  width:100%; margin:0 auto; height:auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://fiabci2024.orientaldaily.com.my/fiabci_desktop2024.jpg');
  aspect-ratio: 3.871;
}
.player-wrapper iframe{
  width:100% !important;
  height:100% !important;
}
.article_container{
  background:linear-gradient(90deg, rgba(77,77,80,1) 100%, rgba(0,0,0,1) 100%);
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-top:1em;
}
.ant-card-body{
  padding:1em;
}
@media only screen and (max-width: 480px) {
  .home_img{
    background-image: url('https://fiabci2024.orientaldaily.com.my/fiabci_mobile2024.jpg');
    aspect-ratio: 1.92;
  }
  .article_container{
    padding:0 1em;
  }
}
@media only screen and (max-width: 600px) {
  .news-text{
    display: none !important;
  }
  .news-image{
    width: 140px;
    height:90px;
  }
  .ant-card-body{
    padding:8px !important;
  }
}
@media only screen and (min-width:600px){
   .news-image{
    width:250px;
    height:180px;
  }
}
@media only screen and (min-width:700px){
  #overall-map{
    height: 350px;
  }
}